import React from 'react'
import { Nav } from '../components/Nav';

export const HomePage = () => {
  return (
    <>
    <Nav/>
    <div>HomePage</div>
    </>
  )
}
